import {backendApi} from "../backend-api-sr"

export const dashboardOverview = (() =>{
    const test = (() => {
        return ""
    })

    const showPackages = ( async (subPath) => {
        var url = '/api/amerta/dashboard/showPackages'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    })
    const showPackagesDetail = ( async (subPath) => {
        var url = '/api/amerta/dashboard/showPackagesDetail'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    })

    return {showPackages,showPackagesDetail};

})()