<template>
  <v-container fluid style="margin-bottom: 100px">
    <v-row align="center" justify="center" >

      <!-- diagram total -->
      <v-col
        cols="12"
        xs="12" 
        sm="12" 
        md="3"
      >
        <!-- <v-card class="pa-md-5 bg_card"> -->
        <v-card class="pa-md-5">
          <div style="height: 395px; width: 100%; background-color: #FFFFFF;">
            <v-list-item two-line style="padding-bottom:0px">
              <v-list-item-content style="padding: 1px 0;">
                <v-list-item-title class="text-h5">
                  <br>
                  Total Pendaftar
                </v-list-item-title>
                <v-list-item-subtitle ></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-card-text style="padding-top: 0px;padding-bottom: 0px;">
              <v-row align="center">
                <v-col class="text-h6" cols="8">
                  
                </v-col>
                <v-col cols="4">
                  

                  <br>
                </v-col>
              </v-row>
            </v-card-text>

            <v-list-item style="padding-top: 0px;padding-bottom:0px;max-height: 35px;">
              <v-list-item-icon>
                <v-icon>mdi-account-multiple</v-icon>
              </v-list-item-icon>
              <v-list-item-subtitle> {{ $store.getters.convertToCurrency(total_shop_summary_last_month) }} Orang</v-list-item-subtitle>
            </v-list-item>

          </div>
        </v-card>
      </v-col>

      <!-- diagram umur -->
      <v-col
        cols="12"
        xs="12" 
        sm="12" 
        md="3"
      >
        <!-- <v-card class="pa-md-4 bg_card">    -->
        <v-card class="pa-md-4">
          <div id="shop_summary_last_month" style="height: 370px; width: 100%;"></div>
          <p style="text-align: right;color: #e50400;font-weight: 700;"> {{ $store.getters.convertToCurrency(total_shop_summary_last_month) }} orang</p>
        </v-card>
        <v-dialog v-model="dialog_sebaran_umur" max-width="1000px">                
          <v-card class="bg_card">
              <v-progress-linear
                      :active="loading_shop_last_month"
                      :indeterminate="loading_shop_last_month"
                      absolute
                      bottom
                      color="deep-purple accent-4"
              ></v-progress-linear>
              <v-card-title>Detail Umur</v-card-title>
              <v-card-text>
                    <v-container fluid>
                      <v-row>
                          <v-col cols="12">
                              <!-- <v-card-title>Umur Customer
                                  <v-spacer></v-spacer>
                                  <v-text-field
                                      v-model="$store.state.text"
                                      append-icon="mdi-magnify"
                                      label="Search..."
                                      single-line
                                      @keyup.enter="search_shop"
                                      hide-details
                                  ></v-text-field>
                              </v-card-title> -->
                              <v-data-table
                                  :headers="header_item_umur"
                                  :items="umur_details"
                                  :options.sync="option_item_shops"
                                  :loading="loading_shop_last_month"
                                  loading-text="Please wait, retrieving data"
                                  :server-items-length="total_item_shops"
                                  :page.sync="pagination_shop"
                                  page-count="10"
                                  class="elevation-1"
                              >
                                <template v-slot:[`item.unit_price`]="{ item }">
                                    {{ $store.getters.convertToCurrency(item.unit_price) }}
                                </template>
                                <template v-slot:[`item.amt_item`]="{ item }">
                                    {{ $store.getters.convertToCurrency(item.amt_item) }}
                                </template>
                              </v-data-table>
                          </v-col>
                      </v-row>
                  </v-container>
              </v-card-text>
              <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close_shop">Close</v-btn>
              </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>

      <!-- diagram paket -->
      <v-col
        cols="12"
        xs="12" 
        sm="12" 
        md="3"
      >
        <!-- <v-card class="pa-md-4 bg_card">    -->
        <v-card class="pa-md-4">
          <div id="shop_summary_this_month" style="height: 370px; width: 100%;"></div>
          <p style="text-align: right;color: #e50400;font-weight: 700;"> {{ $store.getters.convertToCurrency(total_shop_summary_this_month) }} paket</p>
        </v-card>
        <v-dialog v-model="dialog_detail_paket" max-width="1000px">                
          <v-card class="bg_card">
              <v-progress-linear
                      :active="loading_shop_last_month"
                      :indeterminate="loading_shop_last_month"
                      absolute
                      bottom
                      color="deep-purple accent-4"
              ></v-progress-linear>
              <v-card-title>Packages Detail</v-card-title>
              <v-card-text>
                    <v-container fluid>
                      <v-row>
                          <v-col cols="12">
                              <!-- <v-card-title>Packages Item
                                  <v-spacer></v-spacer>
                                  <v-text-field
                                      v-model="$store.state.text"
                                      append-icon="mdi-magnify"
                                      label="Search..."
                                      single-line
                                      @keyup.enter="search_shop"
                                      hide-details
                                  ></v-text-field>
                              </v-card-title> -->
                              <v-data-table
                                  :headers="header_item_shops"
                                  :items="paket_details"
                                  :options.sync="option_item_shops"
                                  
                                  loading-text="Please wait, retrieving data"
                                  :server-items-length="total_item_shops"
                                  :page.sync="pagination_shop"
                                  page-count="10"
                                  class="elevation-1"
                              >
                                <template v-slot:[`item.art_price`]="{ item }">
                                    {{ $store.getters.convertToCurrency(item.art_price) }}
                                </template>
                                <template v-slot:[`item.dt_modified`]="{ item }">
                                        {{ (new Date(new Date(item.dt_modified) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10) }}
                                    </template>
                              </v-data-table>
                          </v-col>
                      </v-row>
                  </v-container>
              </v-card-text>
              <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close_shop">Close</v-btn>
              </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>

    <!-- diagram bawah -->
    <v-row class="mt-2">
      <v-col
        cols="12"
        xs="12" 
        sm="12" 
        md="12"
      >
        <v-card class="pa-md-5">   
          <div id="payment" style="height: 370px; width: 100%;"></div>
          <p></p>
        </v-card>
        <!-- dialog kematian -->
        <v-dialog v-model="dialog_payment" max-width="1000px">                
          <v-card>
              <v-progress-linear
                      :active="loading_payment"
                      :indeterminate="loading_payment"
                      absolute
                      bottom
                      color="deep-purple accent-4"
              ></v-progress-linear>
              <v-card-title>Detail Kematian</v-card-title>
              <v-card-text>
                    <v-container fluid>
                      <v-row>
                          <v-col cols="12">
                              <!-- <v-card-title>Detail Pendaftaran
                                  <v-spacer></v-spacer>
                                  <v-text-field
                                      v-model="$store.state.text"
                                      append-icon="mdi-magnify"
                                      label="Search..."
                                      single-line
                                      @keyup.enter="search_payment"
                                      hide-details
                                  ></v-text-field>
                              </v-card-title> -->
                              <v-data-table
                                  :headers="header_item_payments"
                                  :items="data_kematians"
                                  :options.sync="option_item_payments"
                                  :loading="loading_payment"
                                  loading-text="Please wait, retrieving data"
                                  :server-items-length="total_item_payments"
                                  :page.sync="pagination_payment"
                                  page-count="10"
                                  class="elevation-1"
                              >
                                <template v-slot:[`item.inv_amt`]="{ item }">
                                    {{ $store.getters.convertToCurrency(item.inv_amt) }}
                                </template>
                                <template v-slot:[`item.pay_amt`]="{ item }">
                                    {{ $store.getters.convertToCurrency(item.pay_amt) }}
                                </template>
                                <template v-slot:[`item.disc_amt`]="{ item }">
                                    {{ $store.getters.convertToCurrency(item.disc_amt) }}
                                </template>
                                <template v-slot:[`item.owing_amt`]="{ item }">
                                    {{ $store.getters.convertToCurrency(item.owing_amt) }}
                                </template>
                              </v-data-table>
                          </v-col>
                      </v-row>
                  </v-container>
              </v-card-text>
              <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close_payment">Close</v-btn>
              </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- dialog pendaftaran -->
        <v-dialog v-model="dialog_inv_payment" max-width="1000px">                
          <v-card class="bg_card">
              <v-progress-linear
                      :active="loading_inv_payment"
                      :indeterminate="loading_inv_payment"
                      absolute
                      bottom
                      color="deep-purple accent-4"
              ></v-progress-linear>
              <v-card-title>Detail Pendaftaran</v-card-title>
              <v-card-text>
                    <v-container fluid>
                      <v-row>
                          <v-col cols="12">
                              <!-- <v-card-title>Item Pendaftaran
                                  <v-spacer></v-spacer>
                                  <v-text-field
                                      v-model="$store.state.text"
                                      append-icon="mdi-magnify"
                                      label="Search..."
                                      single-line
                                      @keyup.enter="search_inv_payment"
                                      hide-details
                                  ></v-text-field>
                              </v-card-title> -->
                              <v-data-table
                                  :headers="header_item_inv_payments"
                                  :items="data_pendaftarans"
                                  :options.sync="option_item_inv_payments"
                                  :loading="loading_inv_payment"
                                  loading-text="Please wait, retrieving data"
                                  :server-items-length="total_item_inv_payments"
                                  :page.sync="pagination_inv_payment"
                                  page-count="10"
                                  class="elevation-1"
                              >
                              <template v-slot:[`item.dt_trans`]="{ item }">
                                {{ (new Date(new Date(item.dt_trans) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10) }}
                              </template>
                              </v-data-table>
                          </v-col>
                      </v-row>
                  </v-container>
              </v-card-text>
              <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close_inv_payment">Close</v-btn>
              </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>

import { dashboardOverview } from "../../../backend-api/amerta/dashboardOverview";

export default {
  data() {
      return {
        display: 'none',
        data_other_payments:{},
        tr_type_other_payment:'',
        period_other_payment: '',
        dialog_other_payment: false,
        pagination_other_payment: 1,
        detail_other_payments: [],
        loading_other_payment: false,
        total_item_other_payment: 10,
        option_item_other_payments: {},
        header_other_payment_items:[
          {
              text: 'Period',
              align: 'start',
              sortable: true,
              value: 'period',
          },
          { text: 'TR Date', value: 'tr_date' },
          { text: 'TR Type', value: 'tr_type' },
          { text: 'Item Notes', value: 'item_notes' },
          { text: 'Amount', value: 'amount' }
        ],
        data_payments: [],
        period_inv: '',
        dialog_inv_payment: false,
        pagination_inv_payment: 1,
        period: '',
        dialog_payment: false,
        pagination_payment: 1,
        loading_payment: false,
        data_item_payments: [],
        total_item_payments: 10,
        header_item_payments:[
          {
              text: 'Doc IC',
              align: 'start',
              sortable: true,
              value: 'rsv_id',
          },
          { text: 'Tanggal', value: 'dt_trans' },
          { text: 'Nama', value: 'nama' },
          { text: 'Alamat', value: 'alamat' },
          { text: 'Kota', value: 'city' },
        ],
        data_kematians: [],
        option_item_payments:{},
        loading_inv_payment: false,
        data_item_inv_payments: [],
        total_item_inv_payments: 10,
        pagination_inv_payment: 1,
        header_item_inv_payments:[
          {
              text: 'Doc ID',
              align: 'start',
              sortable: true,
              value: 'doc_id',
          },
          { text: 'Tanggal', value: 'dt_trans' },
          { text: 'Nama', value: 'nama' },
          { text: 'Alamat', value: 'alamat' },
          { text: 'Kota', value: 'city' },
        ],
        data_pendaftarans: [],
        option_item_inv_payments:{},
        shop_summary_lastest: [],
        total_shop_summary_last_month: 0,
        shop_summary_recents: [],
        total_shop_summary_this_month: 0,
        pos_shop: '',
        dialog_detail_paket: false,
        pagination_shop: 1, 
        last_month: '',
        loading_shop_last_month: false,
        detail_shop_summary_lastest: [],
        total_item_shops: 10,
        option_item_shops:{},
        header_item_shops:[
          {
              text: 'Doc ID',
              align: 'start',
              sortable: true,
              value: 'doc_id',
          },
          { text: 'Nama', value: 'nama' },
          { text: 'Alamat', value: 'alamat' },
          { text: 'Kota', value: 'city' }
        ],
        paket_details: [],
        dialog_sebaran_umur: false,
        header_item_umur:[
          {
              text: 'Nama',
              align: 'start',
              sortable: true,
              value: 'nama',
          },
          { text: 'Alamat', value: 'alamat' },
          { text: 'Kota', value: 'city' },
          { text: 'Tanggal Lahir', value: 'ultah' }
        ],
        umur_details: [],
      }
  },
  async mounted(){
      await axios.get(`${process.env.VUE_APP_URL}/api/amerta/dashboard/showSebaranUmur`, { 
        headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
      })
      .then(res => {
        this.shop_summary_lastest = res.data.data ? res.data.data : []
        this.total_shop_summary_last_month = res.data.message ? res.data.message : 0

        this.renderChartShopLatest(this.shop_summary_lastest)

        // console.log(res.data.message);
      });

      await axios.get(`${process.env.VUE_APP_URL}/api/amerta/dashboard/showPackages`, { 
        headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
      })
      .then(res => {
        this.shop_summary_recents = res.data.data ? res.data.data : []
        this.total_shop_summary_this_month = res.data.message ? res.data.message : 0

        this.renderChartShop(this.shop_summary_recents)
        // console.log(res.data.message);
      });

      await axios.get(`${process.env.VUE_APP_URL}/api/amerta/dashboard/showDaftarBerpulang`, { 
        headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
      })
      .then(res => {
        
        this.data_payments = res.data
        this.renderChartBarPayment(this.data_payments)

      });

  },
  methods:{
      search_shop(){
        this.getItemShop(page, itemsPerPage, this.last_month)
      },
      search_other_payment(){
        this.getItemOtherPayment(page, itemsPerPage, this.period_other_payment)
      },
      search_payment(){
        this.getItemPayment(page, itemsPerPage, this.period)
      },
      search_inv_payment(){
        this.getItemInvPayment(page, itemsPerPage, this.period_inv)
      },
      close_payment(){
        this.dialog_payment = false
      },
      close_other_payment(){
        this.dialog_other_payment = false
      },
      close_inv_payment(){
        this.dialog_inv_payment = false
      },
      close_shop(){
        this.dialog_detail_paket = false
        this.dialog_sebaran_umur  = false
      },
      
      renderChartBarPayment(datas){
        var chart3 = new CanvasJS.Chart("payment", {
                      animationEnabled: true,
                      theme: "light2",
                      title: {
                          text: "Daftar & Berpulang",
                          fontFamily: "Calibri",
                          fontSize: 20
                      },
                      axisX:{
                        title : "Year-Month"
                      },
                      axisY: {
                        title: "Orang",
                        titleFontColor: "#4F81BC",
                        lineColor: "#4F81BC",
                        labelFontColor: "#4F81BC",
                        tickColor: "#4F81BC",
                        includeZero: false
                      },
                      data: [
                      {
                        type: "column",
                        name: "Pendaftaran",
                        click: this.onClickInvPayment,
                        showInLegend: true,
                        indexLabelPlacement: "inside",  
                        indexLabelOrientation: "vertical",
                        color: "#f79b07",
                        yValueFormatString: "# Orang",
                        indexLabel: "{y}",
                        dataPoints: datas.payment
                      },
                      {
                        type: "column",
                        name: "Berpulang",
                        click: this.onClickPayment,
                        showInLegend: true,
                        indexLabelPlacement: "inside",  
                        indexLabelOrientation: "vertical",
                        color: "#07dbf7",
                        yValueFormatString: "# Orang",
                        indexLabel: "{y}",
                        dataPoints: datas.piutang
                      }
                      ]
                    });
        chart3.render();
      },
      onClickInvPayment(e){
        this.data_pendaftarans=[]
        this.period_inv = e.dataPoint.label
        this.dialog_inv_payment = true
        this.pagination_inv_payment = 1

        this.getItemInvPayment(1,10,e.dataPoint.label)
      },
      async getItemInvPayment(page = 1, itemsPerPage = 10, item){
        // this.loading_inv_payment = true
        await axios.get(`${process.env.VUE_APP_URL}/api/amerta/dashboard/showDaftarDetail?period=${this.period_inv ? this.period_inv : ""}&page=${page > 1 ? page : 1}&pagination=${itemsPerPage}`, { 
            headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
        })
        .then(res => {
            this.data_pendaftarans = res.data.data
        })
      },
      onClickPayment(e){
        this.period = e.dataPoint.label
        this.dialog_payment = true
        this.pagination_payment = 1

        this.getItemPayment(1,10,e.dataPoint.label)
      },
      async getItemPayment(page = 1, itemsPerPage = 10, item){

          await axios.get(`${process.env.VUE_APP_URL}/api/amerta/dashboard/showKematianDetail?period=${this.period ? this.period : ""}&page=${page > 1 ? page : 1}&pagination=${itemsPerPage}`, { 
              headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
          })
          .then(res => {
              this.data_item_payments = res.data.data
          })
      },
      renderChartShopLatest(data){

        var chart4 = new CanvasJS.Chart("shop_summary_last_month", {
                      animationEnabled: true,
                      theme: "light2",
                      title: {
                          text: "Sebaran Usia",
                          fontFamily: "Calibri",
                          fontSize: 20
                      },
                      data: [{
                        type: "pie",
                        click: this.onClickShopLatest,
                        startAngle: 240,
                        yValueFormatString: "##0.00\"%\"",
                        indexLabel: "{ket_umur} ({jumlah})",
                        toolTipContent: "<b>{ket_umur}:</b> {y} ({jumlah})",
                        dataPoints: this.shop_summary_lastest
                      }]
                    });
        chart4.render();
      },
      onClickShopLatest(e){
        this.umur_details=[]
        this.umur = e.dataPoint.umur
        this.dialog_sebaran_umur = true
        this.pagination_shop = 1
        this.getItemUmur(1, 10, this.umur)
      },
      getItemUmur(page = 1, itemsPerPage = 10, item){
        // this.loading_shop_last_month = true

        axios.get(`${process.env.VUE_APP_URL}/api/amerta/dashboard/showUmurDetail?umur=${item}&page=${page > 1 ? page : 1}&pagination=${itemsPerPage}`, { 
              headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
          })
          .then(res => {
              // this.loading_shop_last_month = false
              this.umur_details = res.data.data
              this.total_item_shops = res.data.total
              this.pagination_shop = res.data.current_page
          })

      },

      
      // untuk menampilkan diagram paket
      renderChartShop(data){
        var chart5 = new CanvasJS.Chart("shop_summary_this_month", {
                      animationEnabled: true,
                      theme: "light2",
                      title: {
                          text: "Package Purchase Summary",
                          fontFamily: "Calibri",
                          fontSize: 20
                      },
                      data: [{
                        type: "pie",
                        click: this.onClickShop,
                        startAngle: 240,
                        yValueFormatString: "##0.00\"%\"",
                        indexLabel: "{nama_paket} ({jumlah})",
                        toolTipContent: "<b>{paket_id}-{nama_paket}:</b> {y} ({jumlah})",
                        dataPoints: this.shop_summary_recents
                      }]
                    });
        chart5.render();
      },
      onClickShop(e){
        this.paket_details=[]
        this.paket_id = e.dataPoint.paket_id
        this.dialog_detail_paket = true
        this.pagination_shop = 1
        this.getItemShop(1, 10, this.paket_id)
      },
      getItemShop(page = 1, itemsPerPage = 10, item){
        // this.loading_shop_last_month = true

        axios.get(`${process.env.VUE_APP_URL}/api/amerta/dashboard/showPackagesDetail?paket_id=${item}&page=${page > 1 ? page : 1}&pagination=${itemsPerPage}`, { 
              headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
          })
          .then(res => {
              // this.loading_shop_last_month = false
              this.paket_details = res.data.data
              this.total_item_shops = res.data.total
              this.pagination_shop = res.data.current_page
          })

      },
  },
  watch: {
      option_item_other_payments:{
        handler () {
            // this.loading_other_payment = true
            const { page, itemsPerPage } = this.option_item_other_payments
            if (this.period_other_payment) {
                this.getItemOtherPayment(page, itemsPerPage, this.period_other_payment)
            }
        },
        deep: true,
      },
      option_item_payments:{
        handler () {
              // this.loading_payment = true
              const { page, itemsPerPage } = this.option_item_payments
              if (this.period) {
                  this.getItemPayment(page, itemsPerPage, this.period)
              }
          },
          deep: true,
      },
      option_item_inv_payments:{
        handler () {
              // this.loading_inv_payment = true
              const { page, itemsPerPage } = this.option_item_inv_payments
              if (this.period_inv) {
                  this.getItemInvPayment(page, itemsPerPage, this.period_inv)
              }
          },
          deep: true,
      },
      option_item_shops:{
        handler () {
              // this.loading_shop_last_month = true
              const { page, itemsPerPage } = this.option_item_shops
              if (this.last_month) {
                  this.getItemShop(page, itemsPerPage, this.last_month)
              }
          },
          deep: true,
      },
  }
}
</script>